import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const elaa = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="elaa">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Early Learning Association Australia</h1>
          <span className="highlight"></span>
        </span>
        <span>Tender Project</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/elaa-featured.png'} alt="ELAA" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />
              <img className="picture" src={'/img/single/woocommerce-icon.png'} width="247" height="50" alt="Woocommerce" />
            </div>
            <a className="button visitwebsite" href="https://elaa.org.au/" target="_blank" rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>Childhood Education, Kindergarten and Early Learning</p>
        <p>Tobstar has won a first tender contract with Early Learning Association Australia to provide support, website design and customised WordPress development responsive for desktop, smartphone and tablet. Also including Event calendar system with ticketing, customised job boards, membership system and application forms powered by WordPress, Woocommerce and Gravity Forms plugins with our content management interface.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/elaa-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/elaa-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/bulk-buys" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">Bulk Buys</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/bills-forecast" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">Bills Forecast</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default elaa


export const Head = () => (
  <>
    <title>Early Learning Association Australia - Tobstar</title>
<meta name="description" content="Childhood Education, Kindergarten and Early Learning" />
  </>
)